
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { ICategoria } from '@/models/Entidades/ICategoria';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import Seo from '@/components/Seo.vue';
import ComboCategoria from '@/components/Produtos/ComboCategoria.vue';
import ServicoCategoria from '@/servicos/ServicoCategoria';
import { IResposta } from '@/core/models/IResposta';
import { IEstrutura } from '@/models/Entidades/IEstrutura';
import { ETipoEstrutura } from '@/models/Enumeradores/ETipoEstrutura';

export default defineComponent({
  name: 'CategoriaCadastro',
  components: {
    ComunicacaoApi,
    ComboCategoria,
    EditorHtml,
    Seo,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();
    const servicoCategoria = new ServicoCategoria();

    const state = reactive({
      categoria: {} as ICategoria,
    });

    async function obterCategoria(codigo:number) {
      appBase.carregando = true;
      state.categoria = await servicoCategoria.obter(codigo);
      appBase.carregando = false;
    }

    function limparDados() {
      state.categoria = {} as ICategoria;
      state.categoria.codigo = 0;
      state.categoria.status = true;
      state.categoria.estrutura = {} as IEstrutura;
      state.categoria.estrutura.codigo = 0;
      state.categoria.estrutura.indexar = true;
      state.categoria.estrutura.tipo = ETipoEstrutura.Categoria;
    }

    onBeforeMount(async () => {
      limparDados();
      const codigoCategoria = obterCodigoRota();
      if (codigoCategoria > 0) {
        await obterCategoria(codigoCategoria);
      }
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações da categoria.');
      if (state.categoria.codigo === 0) {
        state.categoria.estrutura.url = 'url';
        appBase.resposta = await servicoCategoria.incluir(state.categoria);
      } else {
        appBase.resposta = await servicoCategoria.atualizar(state.categoria);
      }

      apresentarRespostaRedirecionamento('Categorias');
    }
    return {
      appBase,
      state,
      salvar,
    };
  },
});
